import { Component } from '@angular/core';
import { AbstractIconComponent } from './abstract-icon.component';

@Component({
  selector: 'app-icon-app-sense',
  styleUrls: ['./icons.scss'],
  standalone: true,
  template: `<svg
    [attr.width]="size"
    [attr.height]="size"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M303.6,346.2h-96.8c-36.7,0-66.5-29.8-66.5-66.5v-45.2c0-36.7,29.8-66.5,66.5-66.5h96.8 c36.7,0,66.5,29.8,66.5,66.5v45.2C370.1,316.4,340.3,346.2,303.6,346.2z M206.9,202.8c-17.5,0-31.7,14.2-31.7,31.7v45.2 c0,17.5,14.2,31.7,31.7,31.7h96.8c17.5,0,31.7-14.2,31.7-31.7v-45.2c0-17.5-14.2-31.7-31.7-31.7H206.9z" />
      <path
        fill-opacity=".9"
        d="M255.2,423.6c-91.8,0-166.4-74.7-166.4-166.5S163.5,90.7,255.2,90.7s166.5,74.7,166.5,166.5 S347,423.6,255.2,423.6z M255.2,108.1c-82.2,0-149.1,66.9-149.1,149.1S173,406.2,255.2,406.2s149.1-66.9,149.1-149.1 S337.4,108.1,255.2,108.1z" />
      <path
        fill="none"
        fill-opacity=".7"
        d="M451,61.3H59.4v119.9c3.4-0.1,6.8-0.5,10.2-0.5c148.9,0,269.6,120.7,269.6,269.6c0,0.9-0.1,1.7-0.1,2.6H451 V61.3z" />
      <path
        fill-opacity=".5"
        d="M255.2,472.9c-119,0-215.7-96.8-215.7-215.8S136.3,41.4,255.2,41.4S471,138.2,471,257.1 S374.2,472.9,255.2,472.9z M255.2,58.8c-109.4,0-198.3,89-198.3,198.4s89,198.4,198.3,198.4s198.4-89,198.4-198.4 S364.6,58.8,255.2,58.8z" />
    </g>
  </svg>`,
})
export class IconAppSenseComponent extends AbstractIconComponent {}
